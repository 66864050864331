export function calculateCountScripts() {
	try {
		return performance
			.getEntries()
			.filter(
				(entry) => entry.entryType === 'resource' && entry.name.includes('.js') && entry.name.includes('group_')
			)
			.length.toString()
	} catch (e) {
		console.error(e)
		return ''
	}
}
